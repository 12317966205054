import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import striptags from 'striptags'
import {useTranslation} from 'next-i18next'
import MediaItem from '../shared/MediaItem'
import GenericLink from '../shared/Link'
import {
  CategoryHighlight,
  HightlightedCategories,
  Paragraph,
} from '../../types/paragraphs'
import type {Response} from '../../types/response'
import {CategoryNode} from '../../types/content-types'
import RichText from '../shared/RichText'

type HighlightedCategoriesProps = {
  paragraph: Paragraph
  response: Response | null
}

const HighlightedCategories = ({
  paragraph,
  response,
}: HighlightedCategoriesProps) => {
  const {t} = useTranslation()
  const paragraphFull = response?.included?.find(
    el => el.id === paragraph.id,
  ) as HightlightedCategories | undefined
  if (!paragraphFull) {
    return null
  }
  const categories = response?.included?.filter(el =>
    paragraphFull.relationships.field_categories.data.find(
      cat => cat.id === el.id,
    ),
  ) as CategoryHighlight[]
  return (
    <section className="section-box c-highlighted-categories">
      <Container fluid>
        <Row>
          <Col>
            <h2 className="mb-4 text-center">
              {paragraphFull.attributes.field_title}
            </h2>
          </Col>
        </Row>
        <Row>
          {categories?.map(cat => {
            const categoryPage = response?.included?.find(
              el => el.id === cat.relationships.field_category_page.data?.id,
            ) as CategoryNode
            if (!categoryPage?.attributes) {
              return null
            }
            const media = response?.included?.find(
              el => el.id === cat.relationships.field_media?.data.id,
            )
            return (
              <Col
                className="c-highlighted-categories__item"
                lg={{
                  span: 6,
                  offset:
                    paragraphFull.relationships.field_categories.data.length <=
                    1
                      ? 3
                      : undefined,
                }}
                key={cat.id}
              >
                <GenericLink
                  link={categoryPage.attributes.path}
                  extraClass="d-flex flex-column text-center text-lg-left align-items-center"
                >
                  <div className="position-relative w-100">
                    {cat.attributes.field_label ? (
                      <span className="position-absolute c-highlighted-categories__tag">
                        {cat.attributes.field_label}
                      </span>
                    ) : null}
                    <MediaItem
                      wrapperClass="c-highlighted-categories__img d-flex position-relative"
                      item={media}
                      imageClass="max-w-100 h-auto"
                      response={response}
                    />
                  </div>
                  <div className="px-4 py-3 c-highlighted-categories__text d-flex flex-column justify-content-between">
                    <div className="c-highlighted-categories__text-title-description-wrapper">
                      <h3 className="font-weight-bold">
                        {cat.attributes.field_title}
                      </h3>
                      {cat.attributes.field_description?.processed ? (
                        // eslint-disable-next-line react/jsx-indent
                        <RichText
                          extraClass="c-highlighted-categories__description"
                          html={cat.attributes.field_description?.processed}
                        />
                      ) : null}
                    </div>
                    {categoryPage.attributes.field_number_of_items ? (
                      // eslint-disable-next-line react/jsx-indent
                      <div>
                        <hr className="c-highlighted-categories__separator" />
                        <div className="c-highlighted-categories__number d-flex align-items-center">
                          <i />
                          <span>
                            {categoryPage.attributes.field_number_of_items}{' '}
                            {striptags(
                              t('highlightedcategories_coursesavailable'),
                            )}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </GenericLink>
              </Col>
            )
          })}
        </Row>
      </Container>
    </section>
  )
}

export default HighlightedCategories
